import type { FeaturedWidgetInstance } from '@aurora/shared-client/components/context/FeaturedWidgetContext/FeaturedWidgetContext';
import type { ProvisionalTextData } from '@aurora/shared-client/components/context/ProvisionalTextContext/ProvisionalTextContext';
import type { QuiltFragment } from '@aurora/shared-generated/types/graphql-types';
import React from 'react';
import type { PageEditorSelectionData } from '../../../helpers/quilt/PageEditorHelper';

/**
 * Edit context for the page
 */
export interface EditContextInterface {
  /**
   * Whether to show edit controls, which are hidden during device preview
   */
  showEditControls: boolean;
  /**
   * The quilt that is used when the page is in edit mode.
   */
  quilt: QuiltFragment;

  /**
   * Updates the Quilt during edit mode as page changes are happening.
   * @param quilt the updated quilt
   * @param text the updated text
   * @param featuredWidgetInstance the updated featured widget
   */
  onChange: (
    quilt: QuiltFragment,
    text?: ProvisionalTextData,
    featuredWidgetInstance?: FeaturedWidgetInstance,
    removedTexts?: ProvisionalTextData[]
  ) => void;

  /**
   * The currently selected item in PageEditor.
   */
  selection: PageEditorSelectionData;

  /**
   * Updates the currently selected item in Page Editor.
   * @param selection the currently selected item.
   */
  setSelection: (selection: PageEditorSelectionData) => void;

  /**
   * A reference to the section or header being smooth scrolled to.
   */
  scrollToItemReference: React.MutableRefObject<HTMLElement>;

  /**
   * Whether the view should be scrolled to an item or not
   */
  scrollToItem: boolean;

  /**
   * Updates scrollToItem value in context
   */
  setScrollToItem: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * Object to store the widget data
   */
  widgetData?: Record<string, unknown>;

  /**
   * Saves the current data of widget.
   * This should be called when data of the widget is needed at the side bar layer.
   * @param props
   */
  setWidgetData?: (props: Record<string, unknown>) => void;

  /**
   * id of the template if the page being worked is a template
   */
  templateId?: string;
}

/**
 * Context that stores the changes in edit mode.
 *
 * @author Manish Shrestha
 */
const EditContext = React.createContext<EditContextInterface>({
  showEditControls: false,
  quilt: null,
  onChange: (): void => null,
  scrollToItemReference: null,
  scrollToItem: false,
  setScrollToItem: (): void => null,
  selection: null,
  setSelection: (): void => null,
  widgetData: {},
  setWidgetData: (): void => null
});
export default EditContext;
